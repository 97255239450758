import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { StaticTemplate } from '../../../templates/staticTemplate'
import { Layout } from '../../../components/layouts/layout'
import { PageMeta } from '../../../components/page-meta'
import { CalculatorSectionModule } from '../../../components/modules/CalculatorSectionModule'
import { PFCashFlowCalculator } from '../../../components/calculators'
import { anchorLinkSections, CrossSellTout } from './constants'
import { usePages } from '../constants'

const CashFlowPage = ({ pageContext, location }) => {
  const [t] = useTranslation()
  const { calculatorsPage, personalFinancePage } = usePages()

  const pageData = [
    {
      sectionTitle: t('CashFlowPage.subTitle', {
        defaultValue: "What's my cash flow?",
      }),
      richBody: {
        nodeType: 'document',
        data: {},
        content: [
          {
            nodeType: 'paragraph',
            content: [
              {
                nodeType: 'text',
                marks: [],
                value: t('CashFlowPage.textContent', {
                  defaultValue:
                    'Do you know how much money you have at the end of each month or year for savings or other priorities? Is your budget in the red or black? Use this calculator to analyze your cash flow monthly and annually to set a smart budget moving forward.',
                }),
                data: {},
              },
            ],
            data: {},
          },
        ],
      },
    },
  ]

  return (
    <StaticTemplate pageContext={pageContext}>
      {({ navMenus }) => (
        <Layout
          location={location}
          pageContext={{ ...pageContext, pathname: personalFinancePage.urlPath }}
          menu={{ ...navMenus, subnav: [calculatorsPage] }}
          showSubnav
        >
          <PageMeta
            language={pageContext.langKey}
            title={t('CashFlowPage.pageTitle', {
              defaultValue: 'Cash Flow Calculator',
            })}
            pathname="/calculators/personal-finance/cash-flow"
          />
          <CalculatorSectionModule
            pageTitle={t('CashFlowPage.title', {
              defaultValue: 'Personal Finance Calculators',
            })}
            sections={pageData}
            calculator={<PFCashFlowCalculator />}
            anchorLinkSections={anchorLinkSections()}
          />
          <CrossSellTout />
        </Layout>
      )}
    </StaticTemplate>
  )
}

CashFlowPage.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object,
  pageContext: PropTypes.object,
}

export { CashFlowPage }
export default CashFlowPage
